<script setup lang="ts">
const { activePortion, updatePortion, createPortion } = usePortionStore();
const { setValidation, required } = useValidation();
const modalStore = useModalStore();
const $api = useApi();

const isUpdatePortion = computed(() => !!activePortion);

const optionsSelectUnit = ref([]);

const portionData = reactive({
  label: '',
  description: '',
  quantity: null,
  unitId: null,
});

watch(
  () => activePortion,
  (updatePortion) => {
    if (!updatePortion) {
      return;
    }

    const { label, description, quantity, unit } = updatePortion;

    portionData.label = label;
    portionData.description = description;
    portionData.quantity = quantity;
    portionData.unitId = unit.id;
  },
  { immediate: true },
);

const validation = setValidation(portionData, {
  label: { required },
  description: { required },
  quantity: { required },
  unitId: { required },
});

async function handleUpsertPortion() {
  if (isUpdatePortion.value) {
    await updatePortion(activePortion.id, portionData);
  } else {
    await createPortion(portionData);
  }
  await modalStore.closeModal();
}

async function fetchUnits() {
  const { data: units } = await $api.post('/units/all', {
    search: '',
  });
  if (units.length) {
    optionsSelectUnit.value = units.map((u) => ({
      value: u.id,
      label: `${u.label} - ${u.symbol}`,
    }));
  }
}

fetchUnits();
</script>

<template>
  <BaseModal
    :title="
      $t(`modal.portionUpsert.${isUpdatePortion ? 'update' : 'create'}.title`)
    "
    :width="900"
  >
    <BaseInput
      v-model="validation.label.$model"
      label="Label"
      type="text"
      :validation="validation.label"
      required
    />
    <BaseInput
      v-model="validation.description.$model"
      label="Description"
      type="textarea"
      :validation="validation.description"
      required
    />

    <BaseInput
      v-model="validation.quantity.$model"
      label="Quantité"
      type="number"
      :validation="validation.quantity"
      required
    />

    <BaseSelect
      v-model="portionData.unitId"
      label="Unité"
      :options="optionsSelectUnit"
      required
    />

    <template #footer>
      <BaseButton
        text="Annuler"
        type="secondary"
        @click="modalStore.closeModal()"
      />
      <BaseButton text="Confirmer" @click="handleUpsertPortion" />
    </template>
  </BaseModal>
</template>

<style scoped></style>
